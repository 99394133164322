<template>
  <slot />
</template>

<script lang="ts" setup>
defineOptions({
  name: 'ElCollectionItem',
  inheritAttrs: false,
})
</script>
